import {
    ApolloClient, ApolloProvider, createHttpLink, gql, InMemoryCache, useQuery
} from "@apollo/client";
import { useState } from 'react';
import { useInterval } from "usehooks-ts";
import './App.scss';
import EditorView from "./pages/EditorView";
import PublicView from "./pages/PublicView";

const queryString: any = {};
window.location.search
    .substring(1)
    .split('&')
    .forEach(entry => {
        queryString[entry.substring(0, entry.indexOf('='))]
            = entry.substring(entry.indexOf('=') + 1);
    });

export const link = createHttpLink({
    uri: (process.env
        ? process.env.REACT_APP_GRAPHQL_URL
        : '/graphql'),
    headers: createAuthHeader()
});

function createAuthHeader() {
    const authValue: string | undefined = queryString['auth'];
    if (authValue) {
        return { Authorization: `Basic ${authValue}` }
    }
    return {}
}

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});

export const APP_DATA_QUERY = gql`
      {
          globalMessage
          tracks {
            key
            name
            duration
            state
          }
          role
      }
    `;

const locksale = queryString['locksale'];

function App() {
    const [currentHash, setCurrentHash] = useState<string | undefined>(undefined);
    

    useInterval(
        () => {
            fetch('/build-hash.txt').then(res => {
                if (res.status === 200) {
                    res.text().then(hash => {
                        if (!currentHash) {
                            setCurrentHash(hash);
                        } else if (currentHash !== hash) {
                            window.location.reload();
                        }
                    });
                }
            });
        },
        6000
    )


    const { loading, error, data } = useQuery(APP_DATA_QUERY, {
        pollInterval: 1000,
        client: client
    });

    if (loading) return <p className="message message--load">
        LOADING
        <span className="one">.</span>
        <span className="two">.</span>
        <span className="three">.</span>
    </p>;

    if (error) return <>`Error! ${error.message}`</>;

    return (
        <ApolloProvider client={client}>
            <div className={'app gradient'}>
                {
                    (!data || ['ADMIN', 'EDITOR'].indexOf(data.role) < 0)
                        ? (
                            <PublicView appData={data} whatToDisplayIfQueryParamIsLocksale={locksale} />
                        ) : (
                            <EditorView initialAppData={data} />
                        )
                }
            </div >
        </ApolloProvider>
    );

}

export default App;
