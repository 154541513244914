import React, { Component } from 'react';
import './Button.scss';
import Icon from './Icon';

export interface ButtonProps {
    label: string;
    type: string;
    clickEvent?: any;
    className?: string;
}

class Button extends Component<ButtonProps, any> {

    render() {
        const icon = this.props.type === 'cancel'
            ? 'cross'
            : this.props.type.indexOf('save') === 0
                ? 'checkmark'
                : null;

        return (
            <button key={this.props.type}
                className={'button button--' + this.props.type + ' ' + this.props.className}
                onClick={this.props.clickEvent}>
                <span className="button__label">
                    {this.props.label}
                </span>
                {icon ? (
                    <span className="button__icon">
                        <Icon type={icon} />
                    </span>
                ) : ''}
            </button>
        )
    }
}

export default Button;
