import * as _ from 'lodash';
import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AppData, TrackData, TrackState } from '../AppData';
import Buttons from '../components/Buttons';
import EventList from '../components/EventList';
import Icon from "../components/Icon";
import TextEdit from '../components/TextEdit';
import Tracks from "../components/Tracks";
import './EditorView.scss';

interface EditorViewProps {
    initialAppData: AppData;
}

interface EditorViewState {
    appData: AppData;
}

class EditorView extends Component<EditorViewProps, EditorViewState> {

    constructor(props: EditorViewProps) {
        super(props);

        this.updateTrackDuration = this.updateTrackDuration.bind(this);
        this.updateTrackState = this.updateTrackState.bind(this);
        this.setTrackWith = this.setTrackWith.bind(this);
        this.updateGlobalMessage = this.updateGlobalMessage.bind(this);

        this.state = {
            appData: _.cloneDeep(props.initialAppData)
        }
    }

    private updateTrackDuration(durationInMinutes: number, trackKey: string) {
        this.setTrackWith(t => t.duration = durationInMinutes, trackKey);
    }

    private updateTrackState(state: TrackState, trackKey: string) {
        this.setTrackWith(t => t.state = state, trackKey);
    }

    private updateGlobalMessage(message: string) {
        this.setState(s => {
            return { appData: { ...s.appData, globalMessage: message } };
        })
    }

    private setTrackWith(mutation: (track: TrackData) => void, trackKey: string) {
        this.setState(s => {
            const newTracks = _.cloneDeep(s.appData.tracks)
            const trackToEdit = _.find(newTracks, (t) => t.key === trackKey);
            if (trackToEdit) {
                mutation(trackToEdit);
                return { appData: { ...s.appData, tracks: newTracks } }
            } else {
                console.error("The track with key " + trackKey + " must exist in array.", newTracks)
                return s;
            }
        })
    }

    onReset() {
        this.setState({ appData: _.cloneDeep(this.props.initialAppData) });
    }

    onSuccessfullySaved() {
        //no-op
    }

    buttons = (isMessageEditor: boolean) => (
        <Buttons isMessageEditor={isMessageEditor}
        appData={this.state.appData}
        initialAppData={this.props.initialAppData}
        onReset={this.onReset.bind(this)}
        onSuccessfullySaved={this.onSuccessfullySaved.bind(this)}
        updateGlobalMessage={this.updateGlobalMessage} />
    )

    trackPanel = () => (
        <Tracks appData={this.state.appData}
            isEditable={true}
            updateTrackDuration={this.updateTrackDuration}
            updateTrackState={this.updateTrackState}
        />
    )

    messagePanel = () => (
        <TextEdit
            appData={this.state.appData}
            updateGlobalMessage={this.updateGlobalMessage}
        />
    )

    render() {
        return (
            <div className="editor-wrapper">
                {(this.props.initialAppData.role === 'ADMIN')
                    ? (
                        <Tabs>
                            <div className="editor-header">
                                <div className="editor-header__title-wrapper">
                                    <span className="editor-header__icon editor-header__icon--left">
                                        <Icon type="logo" />
                                    </span>
                                    <h1 className="editor-header__title">Status / Waiting Time Admin</h1>
                                </div>
                                <TabList>
                                    <Tab>status</Tab>
                                    <Tab>special message</Tab>
                                </TabList>
                            </div>
                            <TabPanel>
                                {this.trackPanel()}
                                {this.buttons(false)}
                            </TabPanel>
                            <TabPanel>
                                {this.messagePanel()}
                                {this.buttons(true)}
                            </TabPanel>
                        </Tabs>
                    ) : (
                        <div className="editor-view">
                            <div className="editor-header">
                                <div className="editor-header__title-wrapper">
                                    <span className="editor-header__icon editor-header__icon--left">
                                        <Icon type="logo" />
                                    </span>
                                    <h1 className="editor-header__title">Status / Waiting Time Admin</h1>
                                </div>
                            </div>
                            <div className="editor-content">
                                {this.trackPanel()}
                            </div>
                            {this.buttons(false)}
                        </div>
                    )
                }
                <EventList appData={this.state.appData}
                    initialData={this.props.initialAppData}
                    isEditable={true}
                    onReset={this.onReset.bind(this)}
                    onSave={this.onSuccessfullySaved.bind(this)}
                    updateGlobalMessage={this.updateGlobalMessage}
                    updateTrackDuration={this.updateTrackDuration}
                    updateTrackState={this.updateTrackState} />
            </div>
        );
    }
}

export default EditorView;