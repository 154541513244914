import React, {Component} from 'react';
import './Buttons.scss';
//TODO: Note: Official support for React Apollo render prop components ended in March 2020. This library is still included in the @apollo/client package, but it no longer receives feature updates or bug fixes.
import { Mutation } from '@apollo/client/react/components';

import {AppData, TrackData, TrackState} from '../AppData';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import Button from './Button';
import { ApolloError } from '@apollo/client';


const updateDataQuery = gql`
  mutation UpdateData($key: ID!, $duration: Int!, $state: TrackState!) {
    updateTrack(trackInput: {
        key: $key,
        duration: $duration,
        state: $state
      }) {
      key,
      duration,
      state
    }
  }
`;

const updateMessageQuery = gql`
  mutation UpdateMessage($message: String) {
    updateGlobalMessage(message: $message)
  }
`;

interface ButtonsProps {
    isMessageEditor: boolean;
    appData: AppData;
    initialAppData: AppData;
    onReset?: any;
    onSuccessfullySaved?: any;
    closeDetail?: any;
    openItem?: TrackData;
    updateGlobalMessage: (message: string) => void
}

interface ButtonsState {
    isSavedMessageVisible: boolean;
    isErrorMessageVisible: boolean;
}

class Buttons extends Component<ButtonsProps, ButtonsState> {

    savedMessageDisplayTimeout?: NodeJS.Timeout;
    savedMessageDisplayDuration = 3000;


    constructor(props: ButtonsProps) {
        super(props);

        this.onSuccessfullyCompleted = this.onSuccessfullyCompleted.bind(this);
        this.onError = this.onError.bind(this);

        this.state = {
            isSavedMessageVisible: false,
            isErrorMessageVisible: false
        };
    }

    disableText() {
        this.props.updateGlobalMessage("");
    }

    hasTrackChanges = () => !_.isEqual(this.props.initialAppData.tracks, this.props.appData.tracks);

    hasMessageChanges = () => this.props.initialAppData.globalMessage !== this.props.appData.globalMessage;

    hasMessage = () => this.props.appData.globalMessage && this.props.appData.globalMessage !== '';

    componentWillUnmount() {
        if (this.savedMessageDisplayTimeout) {
            clearTimeout(this.savedMessageDisplayTimeout);
        }
    }

    hideSavedMessage() {
        this.setState({
            isSavedMessageVisible: false
        });
        if (this.props.closeDetail) {
            this.props.closeDetail();
        }
    }

    onSuccessfullyCompleted = () => {
        this.props.onSuccessfullySaved();
        this.setState({
            isSavedMessageVisible: true
        });
        this.savedMessageDisplayTimeout = setTimeout(() => {
            this.hideSavedMessage();
        }, this.savedMessageDisplayDuration);
    };

    onError = (error: ApolloError) => {
        console.error("Error while saving.", error);
        this.setState({
            isErrorMessageVisible: true
        });
    };

    // noinspection RequiredAttributes
    messageButtons = () => (
        <div className={'button-wrapper ' + ((this.hasMessageChanges() || this.hasMessage())
            ? 'visible'
            : 'hidden')}>
            <Button className={(this.hasMessage()
                ? 'visible'
                : 'hidden') + ' ' + (this.hasMessageChanges()
                ? ''
                : 'full-width')}
                    type="disable"
                    label="disable"
                    clickEvent={this.disableText.bind(this)}/>
            <Mutation mutation={updateMessageQuery} fetchPolicy={"no-cache"} onCompleted={this.onSuccessfullyCompleted} onError={this.onError}>
                {(updateMessage: any, {error}: any) => (
                    <Button className={(this.hasMessageChanges()
                        ? 'visible'
                        : 'hidden') + ' ' + (this.hasMessage()
                        ? ''
                        : 'full-width')}
                            type="save"
                            label="save"
                            clickEvent={() => {
                                updateMessage({
                                    variables:
                                        {
                                            message: this.props.appData.globalMessage
                                        }
                                });
                            }}/>
                )}
            </Mutation>
        </div>
    );

    saveTrack = (trackData: TrackData, updateCall: any) => {
        const trackState = Object.keys(TrackState).filter(key => (TrackState as any)[key] === trackData.state)[0]
            || Object.keys(TrackState).filter(key => key === trackData.state)[0];
        updateCall({
            variables: {
                key: trackData.key,
                duration: parseInt(trackData.duration.toString(), 10),
                state: trackState ? trackState.valueOf() : TrackState.OPEN
            }
        });
    };

    // noinspection RequiredAttributes
    trackButtons = () => <div className={'button-wrapper ' + (this.hasTrackChanges() ? 'visible' : 'hidden')}>
        <Button className="mobile-hidden"
                type="cancel"
                label="cancel"
                clickEvent={this.props.onReset}/>
        <Mutation mutation={updateDataQuery} fetchPolicy={"no-cache"} onCompleted={this.onSuccessfullyCompleted} onError={this.onError}>
            {(updateData: any, {error}: any) => (
                <Button className="mobile-full-width"
                        type="save"
                        label="save"
                        clickEvent={() => {
                            if (this.props.openItem) {
                                this.saveTrack(this.props.openItem, updateData);
                            } else {
                                this.props.appData.tracks.forEach((track: TrackData) => {
                                    this.saveTrack(track, updateData);
                                });
                            }
                        }}/>
            )}
        </Mutation>
    </div>;

    savedButton = () => <Button className={this.state.isSavedMessageVisible ? 'visible' : 'hidden'}
                                type="saved"
                                label="saved"
                                clickEvent={() => {
                                    this.hideSavedMessage();
                                }}/>;

    errorButton = () => <Button className={this.state.isErrorMessageVisible ? 'visible' : 'hidden'}
                                type="error"
                                label="something went wrong, please try again later"
                                clickEvent={() => {
                                    this.setState({
                                        isErrorMessageVisible: false
                                    });
                                }}/>;

    render() {
        return (
            <footer>
                {this.savedButton()}
                {this.errorButton()}
                {this.props.isMessageEditor
                    ? this.messageButtons()
                    : this.trackButtons()}
            </footer>
        );
    }
}

export default Buttons;
