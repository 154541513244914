import React, { Component } from 'react';
import 'rmc-picker/assets/index.css';
import './StateDurationPicker.css';
import { TrackData, TrackState } from '../AppData';
import Picker from 'rmc-picker/lib/Picker';
import ToggleBox from './ToggleBox';

interface StateDurationPickerState {
    pickerItemValues: string[];
}

interface StateDurationPickerProps {
    track: TrackData;
    itemHeight: number;
    scrollerHeight: number;
    updateTrackDuration: (durationInMinutes: number) => void
    updateTrackState: (state: TrackState) => void
}

class StateDurationPicker extends Component<StateDurationPickerProps, StateDurationPickerState> {
    constructor(props: StateDurationPickerProps) {
        super(props);

        this.determineSelectedValue = this.determineSelectedValue.bind(this);

        const pickerItemValues: string[] = [
            TrackState.CLOSED.valueOf(),
        ];
        for (let i = 0; i <= 240; i += 5) {
            pickerItemValues.push(i.toString());
        }

        this.state = {
            pickerItemValues: pickerItemValues
        };
    }

    handleChange = (value: any) => {
        const intValue = parseInt(value, 10);

        if (isNaN(intValue)) {
            this.props.updateTrackState(value.toUpperCase());
            // when CLOSED is selected the track duration is set default to 0
            this.props.updateTrackDuration(0)
        } else {
            this.props.updateTrackDuration(intValue);
            this.props.track.state === TrackState.CLOSED && this.props.updateTrackState(TrackState.OPEN);
        }
    };

    private determineSelectedValue(): string | undefined {
        const selectedValue = this.props.track.state === TrackState.CLOSED
            ? TrackState.CLOSED
            : this.props.track.duration.toString();
        return selectedValue;
    }

    toggleHandleChange: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isNewValueChecked = event.target.checked
        if (isNewValueChecked) {
            this.props.updateTrackState(TrackState.SOLD_OUT);
        } else {
            //Because the server keeps the TrackState in one field, we always switch back to CLOSED. It would be better if we switch back
            //to open if the state before toggling to SOLD_OUT was open and to CLOSED if the state before toggling to SOLD_OUT was CLOSED.
            //However, this would need a backend change.
            this.props.updateTrackState(TrackState.OPEN);
        }
    }

    render() {
        const { pickerItemValues } = this.state;

        return (
            <>
                <Picker
                    onValueChange={this.handleChange}
                    selectedValue={this.determineSelectedValue()}
                    className="picker-container"
                    indicatorClassName="picker-item-selected"
                >
                    {pickerItemValues && pickerItemValues.map((i: string) => (
                        <Picker.Item className="picker-item" value={i} key={i}>{i}</Picker.Item>
                    ))}
                </Picker>
                <ToggleBox isOn={this.props.track.state === TrackState.SOLD_OUT}
                    onChange={this.toggleHandleChange} />
            </>

        )
    }
}

export default StateDurationPicker;
