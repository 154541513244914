import _ from 'lodash';
import { Component } from 'react';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { AppData, IconColors, SoldOutBehavior, TrackData, TrackState } from '../AppData';
import Buttons from './Buttons';
import EventEdit from './EventEdit';
import './EventList.scss';
import Icon from './Icon';
import IconAnim from './IconAnim';
import TextEdit from './TextEdit';

interface EventListProps {
    appData: AppData;
    initialData: AppData;
    isEditable: boolean;
    onSave?: any;
    onReset?: any;
    updateGlobalMessage: (message: string) => void
    updateTrackDuration: (durationInMinutes: number, trackKey: string) => void
    updateTrackState: (state: TrackState, trackKey: string) => void
    whatToDisplayIfTrackIsSoldOut?: SoldOutBehavior;
}

interface EventListState {
    isPaneOpen: boolean;
    openItemKey?: string
}

const globalMessageKey = "global-message"
const globalMessageKeyWithUnderline = "global_message"

const globalMessageTitle = "special text"
class EventList extends Component<EventListProps, EventListState> {

    el: HTMLElement | null = null;
    swipeStart: number = 0;
    swipeDist: number = 0;
    minSwipeDist: number = 150;

    constructor(props: EventListProps) {
        super(props);

        this.state = {
            isPaneOpen: false,
            openItemKey: undefined
        };
    }

    getLink = (url: string, icon: JSX.Element, track?: TrackData) => {

        const classSuffix = track !== undefined ?
            track.state.toLocaleLowerCase()
            : (this.props.appData.globalMessage && this.props.appData.globalMessage.trim() !== '') ? 'open' : 'closed';

        const linkName = track ? track.name : globalMessageTitle;


        return (
            <li className={'event-list__container__item event-list__container__item--' + classSuffix}
                key={track ? track.key : globalMessageKey}>
                <button onClick={(event: any) => this.props.isEditable ? this.setState({
                    isPaneOpen: true,
                    openItemKey: track ? track.key : globalMessageKey
                }) : event.preventDefault()}>
                    <span className={`${this.props.whatToDisplayIfTrackIsSoldOut === SoldOutBehavior.DisplaySoldOutInfo ? "sold_out__icon--track" : ""} event-list__container__item__icon event-list__container__item__icon--track`}>
                        {icon}
                    </span>
                    {
                        this.props.isEditable
                            ? (
                                <span className="event-list__container__item__icon event-list__container__item__icon--arrow">
                                    <Icon type="arrow" />
                                </span>
                            ) : ''
                    }
                    {
                        (track && track.duration !== null)
                            ? (

                                <span className="event-list__container__item__content">
                                    <span  className={`${this.props.whatToDisplayIfTrackIsSoldOut === SoldOutBehavior.DisplaySoldOutInfo ? 'sold_out__label' : ''}`}>
                                        {track.name}
                                    </span>
                                    <span className={`${this.props.whatToDisplayIfTrackIsSoldOut === SoldOutBehavior.DisplaySoldOutInfo ? "sold_out__state" : ""} state`}>
                                        {this.props.whatToDisplayIfTrackIsSoldOut === SoldOutBehavior.DisplaySoldOutInfo ?
                                            track.state !== TrackState.OPEN
                                                ? (track.state as TrackState).valueOf().replace('_', ' ')
                                                : track.duration + '’'
                                            : track.state !== TrackState.OPEN && track.state !== TrackState.SOLD_OUT ?
                                                (track.state as TrackState).valueOf().replace('_', ' ')
                                                : track.duration + '’'}
                                    </span>
                                </span>
                            ) : (
                                <span className="event-list__container__item__content">
                                    {linkName.split(' ').map((namePart: string) =>
                                    (
                                        <span key={namePart}>
                                            {namePart}
                                        </span>
                                    )
                                    )}
                                </span>
                            )
                    }
                </button>
            </li>
        );
    };

    closeDetail = () => {
        this.setState({
            isPaneOpen: false
        });
    };

    componentDidMount() {
        if (this.el) {
            Modal.setAppElement(this.el);
        }
    }

    getDetailContent() {
        const openItem: TrackData | undefined = _.find(this.props.appData.tracks, t => t.key === this.state.openItemKey)
        if (openItem) {
            return <EventEdit track={openItem}
                updateTrackDuration={(durationInMinutes: number) => this.props.updateTrackDuration(durationInMinutes, openItem.key)}
                updateTrackState={(state: TrackState) => this.props.updateTrackState(state, openItem.key)}
            />
        } else {
            return <TextEdit
                appData={this.props.appData}
                updateGlobalMessage={this.props.updateGlobalMessage} />
        }
    };

    buttons = (isMessageEditor: boolean, openItem?: TrackData) => (
        <Buttons isMessageEditor={this.state.openItemKey === globalMessageKey}
            appData={this.props.appData}
            openItem={openItem}
            initialAppData={this.props.initialData}
            closeDetail={this.closeDetail}
            onSuccessfullySaved={this.props.onSave}
            onReset={this.props.onReset} updateGlobalMessage={this.props.updateGlobalMessage} />
    )

    slidingPane = (icon: JSX.Element, title: string, isMessageEditor: boolean) => (
        <SlidingPane
            className='gradient'
            isOpen={this.state.isPaneOpen}
            title={
                <span className="slide-pane__header--detail">
                    <span className="slide-pane__header--detail__icon">
                        {icon}
                    </span>
                    <span className="slide-pane__header--detail__title">
                        {title}
                    </span>
                </span>
            }
            width='100%'
            onRequestClose={() => {
                this.props.onReset();
                this.setState({ isPaneOpen: false });
            }}>
            {this.getDetailContent()}
            {this.buttons(isMessageEditor, undefined)}
        </SlidingPane>
    )

    render() {

        const openItem: TrackData | undefined = _.find(this.props.appData.tracks, t => t.key === this.state.openItemKey)

        const itemKeyWithUnderline = (k: string) => k.replace('-', '_')

        const typeLinks = this.props.appData.tracks.map((track: TrackData) =>
            this.getLink(
                '/edit/type/' + track.key,
                <IconAnim type={itemKeyWithUnderline(track.key)} bgColor={track.state === TrackState.OPEN ? IconColors[itemKeyWithUnderline(track.key)] : 'transparent'} />,
                track
            ));

        return (
            <div className="event-list"
                onTouchStart={(event) => {
                    this.swipeStart = event.touches[0].clientX;
                }}
                onTouchMove={(event) => {
                    this.swipeDist = event.touches[0].clientX - this.swipeStart;
                }}
                onTouchEnd={() => {
                    const isPaneOpen = (this.state.isPaneOpen && this.swipeDist < this.minSwipeDist)

                    if (isPaneOpen !== this.state.isPaneOpen) {
                        this.setState({ isPaneOpen });
                    }
                }}
                ref={ref => this.el = ref}
            >
                <div className="slide-pane__header">
                    <div className="slide-pane__title-wrapper slide-pane__title-wrapper--list">
                        <span className="slide-pane__header__icon">
                            <Icon type="logo" uid={'mobile-header-logo'} />
                        </span>
                        <h2 className="slide-pane__title slide-pane__title--overview">Status / Waiting Time Admin</h2>
                    </div>
                </div>
                <ul className="event-list__container">
                    {typeLinks}
                    {(this.props.appData.role === 'ADMIN') ? this.getLink('/edit/infotext', <Icon type={globalMessageKeyWithUnderline} bgColor={IconColors[globalMessageKeyWithUnderline]} />) : '' }
                </ul>

                {
                    (this.state.openItemKey === globalMessageKey) && this.slidingPane(
                        <Icon type={globalMessageKeyWithUnderline} bgColor={globalMessageKeyWithUnderline} />,
                        globalMessageTitle,
                        true)
                }

                {
                    openItem && this.slidingPane(
                        <IconAnim type={itemKeyWithUnderline(openItem.key)} bgColor={IconColors[itemKeyWithUnderline(openItem.key)]} />,
                        openItem.name,
                        false)
                }
            </div>
        );
    }
}

export default EventList;
