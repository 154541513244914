import _ from 'lodash';
import React, { Component } from 'react';
import ContentEditable from 'react-contenteditable';
import { AppData } from '../AppData';
import './TextEdit.scss';

interface TextEditProps {
    appData: AppData;
    updateGlobalMessage : (message : string) => void
}

class TextEdit extends Component<TextEditProps, {}> {

    handleChange = (event: any) => {
        const elem = event.currentTarget as HTMLElement;
        this.props.updateGlobalMessage(elem && elem.textContent ? elem.textContent : "")
    }

    render() {
        return (
            <p className="special-message-input">
                {_.isEmpty(this.props.appData.globalMessage) && (<span className="special-message-input__placeholder">
                    Enter a message
                </span>)}
                <ContentEditable
                    html={this.props.appData.globalMessage ? this.props.appData.globalMessage : ""} // innerHTML of the editable div
                    disabled={false} // use true to disable edition
                    onChange={this.handleChange} // handle innerHTML change
                    tagName="span"
                    className="special-message-input__content"
                />
            </p>
        )
    }
}

export default TextEdit;