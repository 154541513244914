import * as _ from 'lodash';
import React, { Component } from 'react';
import './ToggleBox.scss';

interface ToggleBoxProps {
  isOn: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

class ToggleBox extends Component<ToggleBoxProps> {

  render() {
    const id = _.uniqueId();
    return (

      <div className='toggle-wrapper'>
        <p>Sold out</p>
        <div className='inside-toggle-wrapper'>
          <input
            checked={this.props.isOn}
            onChange={this.props.onChange}
            className="react-switch-checkbox"
            id={`react-switch-new-${id}`}
            type="checkbox"
          />
          <label
            className={`${this.props.isOn ? "react-switch-toggled" : ""} react-switch-label`}
            htmlFor={`react-switch-new-${id}`}
          >
            <span className={`react-switch-button`} />
          </label>
        </div>
      </div>
    );
  }
}

export default ToggleBox;