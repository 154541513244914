import React, { Component } from 'react';
import CountUp from 'react-countup';
import { NumberData } from '../AppData';

interface NumberCounterState {
    startNumber: number | undefined;
    endNumber: number;
}

class NumberCounter extends Component {

    state: NumberCounterState = {} as NumberCounterState;

    constructor(public props: NumberData) {
        super(props);
        this.state = {
            startNumber: props.displayNumber,
            endNumber: props.displayNumber
        };
    }

    componentWillReceiveProps(nextProps: NumberData) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.displayNumber !== this.state.endNumber) {
            this.setState({
                startNumber: this.state.endNumber,
                endNumber: nextProps.displayNumber
            });
        }
        this.setState({
            displayNumber: nextProps.displayNumber
        });
    }

    render() {
        return (
            <span className="info-bubble__counter">
                <CountUp start={this.state.startNumber} end={this.state.endNumber} suffix={'’'} delay={0}>
                    {({ countUpRef }) => (
                        <span ref={countUpRef} />
                    )}
                </CountUp>
            </span>
        );
    }
}

export default NumberCounter;
