import React, { Component } from 'react';
import { TrackData, TrackState } from '../AppData';
import StateDurationPicker from './StateDurationPicker';
import './TextEdit.scss';

interface EventEditProps {
    track: TrackData;
    updateTrackDuration: (durationInMinutes: number) => void
    updateTrackState: (state: TrackState) => void
}

class EventEdit extends Component<EventEditProps, any> {

    render() {
        return (
            <div className="slide-pane__content--bubble">
                <StateDurationPicker track={this.props.track}
                itemHeight={70}
                scrollerHeight={350} 
                updateTrackDuration={this.props.updateTrackDuration } 
                updateTrackState={this.props.updateTrackState } />
            </div>
        )
    }
}

export default EventEdit;
