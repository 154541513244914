import { Component } from 'react';
import { AppData, SoldOutBehavior, TrackData, TrackState } from '../AppData';
import Track from './Track';
import './Tracks.scss';

interface TracksProps {
    appData: AppData;
    isEditable: boolean;
    updateTrackDuration: (durationInMinutes: number, trackKey: string) => void
    updateTrackState: (state: TrackState, trackKey: string) => void
    whatToDisplayIfTrackIsSoldOut?: SoldOutBehavior
}

class Tracks extends Component<TracksProps, {}> {

    render() {
        return (
            <div className="info-bubbles-wrapper">
                <div className="info-bubbles">
                    {this.props.appData.tracks
                        ? this.props.appData.tracks.map((track: TrackData) => {
                            return (
                                <Track
                                    key={track.key}
                                    track={track}
                                    isEditable={this.props.isEditable}
                                    updateTrackDuration={(durationInMinutes: number) => this.props.updateTrackDuration(durationInMinutes, track.key)}
                                    updateTrackState={(state: TrackState) => this.props.updateTrackState(state, track.key)} 
                                    whatToDisplayIfTrackIsSoldOut={this.props.whatToDisplayIfTrackIsSoldOut}
                                    />
                            )
                        })
                        : undefined}
                </div>
            </div>
        );
    }
}

export default Tracks;
