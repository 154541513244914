import React, { Component } from 'react';
import NumberCounter from './NumberCounter';
import './Track.scss';
import { TrackData, TrackState, IconColors, SoldOutBehavior } from '../AppData';
import StateDurationPicker from './StateDurationPicker';
import IconAnim from './IconAnim';

interface TrackProps {
    track: TrackData;
    isEditable: boolean;
    updateTrackDuration: (durationInMinutes: number) => void
    updateTrackState: (state: TrackState) => void
    whatToDisplayIfTrackIsSoldOut?: SoldOutBehavior;
}


class Track extends Component<TrackProps, any> {

    typeColors: any = {
        glider: '#ff0000',
        flyer: '#00ff00',
        cart: '#0000ff',
        trottibike: '#ffff00'
    };

    constructor(public props: TrackProps) {
        super(props);
    }

    displayTrackState = (state: TrackState) => {
        switch (state) {
            case TrackState.SOLD_OUT:
                return 'SOLD OUT'
            default:
                return state
        }
    }

    render() {
        const lodashKey = this.props.track.key.replace('-', '_');
        const state = this.props.track.state.toLowerCase().replace(' ', '_');
        let soldOutBehavior;
        if (this.props.whatToDisplayIfTrackIsSoldOut === SoldOutBehavior.DisplayWaitingTime) {
            soldOutBehavior = this.props.track.state === TrackState.SOLD_OUT;
        }

        return (
            <div className={'info-bubble gradient info-bubble--' + state}>
                {
                    this.props.isEditable
                        ? <StateDurationPicker track={this.props.track}
                            itemHeight={40}
                            scrollerHeight={200} 
                            updateTrackDuration={this.props.updateTrackDuration}
                            updateTrackState={this.props.updateTrackState}
                            />
                        : this.props.track.state === TrackState.OPEN  || soldOutBehavior ?
                            <NumberCounter displayNumber={this.props.track.duration} />
                            : <span className={'info-bubble__state'}>
                                {(this.displayTrackState(this.props.track.state))}
                            </span>
                }
                < span className={`${this.props.whatToDisplayIfTrackIsSoldOut === SoldOutBehavior.DisplaySoldOutInfo ? "sold_out-bubble__icon" : ""} info-bubble__icon`} >
                    <IconAnim type={lodashKey}
                        bgColor={this.props.whatToDisplayIfTrackIsSoldOut === SoldOutBehavior.DisplaySoldOutInfo ?
                            state === 'open'
                                ? IconColors[lodashKey]
                                : 'transparent'
                            : state === 'open' || state === 'sold_out'
                                ? IconColors[lodashKey]
                                : 'transparent'} />
                </span >
                <span className="info-bubble__label">{this.props.track.name}</span>
            </div >
        )
    }
}

export default Track;
