export interface AppData {
    globalMessage?: string;
    tracks: TrackData[];
    role: string;
}

export interface TrackData {
    key: string;
    name: string;
    state: TrackState;
    duration: number;
}

export interface NumberData {
    displayNumber: number;
}

export enum TrackState {
    CLOSED = 'CLOSED',
    OPEN = 'OPEN',
    SOLD_OUT = 'SOLD_OUT',
}

export enum SoldOutBehavior{
    DisplaySoldOutInfo,
    DisplayWaitingTime
  }

export const IconColors: any = {
    first_flyer: '#d8127d',
    first_glider: '#3c8da7',
    mountain_cart: '#5e6474',
    trottibike: '#6c90c0',
    global_message: '#99ce42'
}