
import React, { Component } from 'react';
import './IconAnim.scss';

interface IconAnimProps {
    type: string;
    color?: string;
    bgColor?: string;
    uid?: string;
    width?: number;
    height?: number;
    speedFps?: number;
}

interface IconAnimState {
    currentIndex: number;
}

class IconAnim extends Component<IconAnimProps, IconAnimState> {

    maxIndex: any = {
        'hourglass': 164,
        'first_flyer': 149,
        'first_glider': 149,
        'mountain_cart': 149,
        'trottibike': 149
    };
    path = '/pnganim/';
    images: HTMLImageElement[] = [];
    interval: any;
    private defaultSpeedConfig = parseFloat(process.env.REACT_APP_ANIMATION_DEFAULT_SPEED ? process.env.REACT_APP_ANIMATION_DEFAULT_SPEED : "");
    defaultSpeed = isNaN(this.defaultSpeedConfig) ? 24 : this.defaultSpeedConfig;

    constructor(props: IconAnimProps) {
        super(props);
        this.state = {
            currentIndex: 0
        }

        for (let i = 0; i <= this.maxIndex[this.props.type]; i++) {
            const url = this.props.type + '_' + this.padNumber(i, 3);
            const image = new Image();
            image.src = this.path + this.props.type + '/' + url + '.png';
            this.images.push(image);
        }
    }

    componentDidMount(){
        this.interval = setInterval(() => {
            const nextIndex = (this.state.currentIndex < this.maxIndex[this.props.type])
                ? (this.state.currentIndex + 1)
                : 0;
            this.setState({ currentIndex: nextIndex });
        }, Math.floor(1000 / (this.props.speedFps || this.defaultSpeed)));
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    padNumber(number: Number, length: Number): String {
        let string = number.toString();
        while (string.length < length) {
            string = '0' + string;
        }
        return string;
    }

    render() {

        return (
            <div className="icon-anim" style={{
                backgroundColor: this.props.bgColor || 'transparent'
            }}>
                <img className="icon-anim__image" src={this.images[this.state.currentIndex].src} alt="" />
            </div>
        );
    }
}

export default IconAnim;