import { Component } from "react";
import { AppData, SoldOutBehavior } from '../AppData';
import EventList from "../components/EventList";
import Icon from "../components/Icon";
import IconAnim from "../components/IconAnim";
import Tracks from '../components/Tracks';
import './PublicView.scss';

interface PublicViewProps {
    appData: AppData;
    whatToDisplayIfQueryParamIsLocksale?: string
}

class PublicView extends Component {

    constructor(public props: PublicViewProps) {
        super(props);
    }

    render() {
        const soldOutBehavior = this.props.whatToDisplayIfQueryParamIsLocksale === 'show' ? SoldOutBehavior.DisplaySoldOutInfo : SoldOutBehavior.DisplayWaitingTime

        return (
            <div className="info-wrapper">
                <div className="info-overview">
                    <div className="info-header">
                        <span className="info-header__icon info-header__icon--left">
                            <Icon type="logo" />
                        </span>
                        <span className="info-header__icon info-header__icon--right">
                            {this.props.appData.globalMessage
                                ? (<Icon type="global_message" />)
                                : (<IconAnim type="hourglass" />)}

                        </span>
                        {this.props.appData.globalMessage
                            ? ''
                            : (
                                <h1 className="info-header__title">Waiting Time</h1>
                            )}
                    </div>
                    {this.props.appData.globalMessage
                        ? (
                            <div className="info-message">
                                <span className="info-message__text">
                                    {this.props.appData.globalMessage}
                                </span>
                            </div>
                        ) : (
                            <Tracks
                                appData={this.props.appData}
                                isEditable={false}
                                updateTrackDuration={(d: number, t: string) => { }}
                                updateTrackState={(s: string, t: string) => { }}
                                whatToDisplayIfTrackIsSoldOut={soldOutBehavior}
                            />
                        )}
                </div>
                <EventList appData={this.props.appData}
                    initialData={this.props.appData}
                    isEditable={false}
                    updateTrackDuration={(d: number, t: string) => { }}
                    updateTrackState={(s: string, t: string) => { }}
                    updateGlobalMessage={(m: string) => { }}
                    whatToDisplayIfTrackIsSoldOut={soldOutBehavior}
                />
            </div>
        )
    }
}

export default PublicView;